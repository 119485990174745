<template>
  <div class="container">

    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="liveTrailer('search')">搜索</div>
      </template>
    </van-search>

    <!-- 直播列表 -->
    <van-empty :description="word" v-if="liveList.length == 0" />
      <div class="live-list" v-if="liveList.length > 0">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onRefresh"
        >
          <div v-for="(item,index) in liveList" :key="index"  class="live-card gray-card" @click="toBVue('/liveDetail', item.id)">
            <img :src="item.cover" alt="">
            <div>
              <div v-if="item.credit" class="card-horn">学分</div>
              <div class="card-title">
                <div class="card-title-time">
                  <div class="tag">时间</div>
                  <p class="time">{{item.timeShow}}</p>
                </div>
                <p>主讲：{{item.speaker}}</p>
              </div>
              <p class="live-desc ellipsis line2">{{item.title}}</p>
            </div>
          </div>
        </van-list>
      </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  name: 'Live',
  data(){
    return {
      liveList:[],
      keyword: '',
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        'offset':0,
        'limit': 10,
      }
    }
  },
  created() {
    this.liveTrailer()
  },
  mounted() {
  },
  methods:{
    onRefresh() {
      this.liveTrailer()
    },
    liveTrailer(type = '') {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      that.formData.keyword = that.keyword
      if (that.formData.offset == 0 || type == 'search') {
        that.liveList = []
      }
      that.refreshStatus = true
      this.$api.LIVETRAILER_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.liveList.push({
            id: item.id,
            title: item.title,
            speaker: item.speaker,
            credit: item.credit ? true : false,
            cover: item.cover ? item.cover : '',
            timeShow: item.start_time + '-' +item.end_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>

<style lang='less' scoped>
  .live-card{
    width:calc(100% - 24px);
    padding:12px;
    display:flex;
    align-items: center;
    margin-left:0;
    margin:16px 0;
  }
  .live-card img{
    display:block;
    width:146px;
    margin-right:14px;
    max-height:100%;
  }

  .live-card .live-desc{
    font-size:14px;
    padding-top:8px;
    color:#000;
  }

  .live-card .time {
    font-size:15px;
  }

  .live-card .card-horn{
    font-size:10px;
  }
</style>
